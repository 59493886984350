import { captureMessage } from '@sentry/nextjs';
import React from 'react';

import { Button } from '@components/Button/Button';
import { Decoration } from '@components/Decoration/Decoration';
import { Image } from '@components/Image/Image';
import { Col } from '@components/Layout/Col/Col';
import { Head } from '@components/Layout/Head';
import { Row } from '@components/Layout/Row/Row';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import { showLiveChat } from '@containers/LiveChat/LiveChat';
import { routes } from '@routes';

import cablesImage from './cables.jpg';
import ChatIcon from '@icons/chat.svg';

export const NotFound: React.FC = () => {
  React.useEffect(() => {
    setTimeout(() => {
      captureMessage(`Page Not Found`, 'info');
    }, 10);
  }, []);

  return (
    <>
      <Head title="Page Not Found" />
      <Section padding={{ top: 170, bottom: 120 }} tag="main">
        <Row verticalAlign="center">
          <Col lg={6} md={7}>
            <Text align="left" className="mb-5" color="gray900" size={56} tag="h1" weight="bold">
              404
            </Text>
            <Paragraph className="mb-6" color="dark" maxWidth={350} size="xl">
              Sorry, we couldn’t find this page. Check the footer links below or contact our tech
              support.
            </Paragraph>
            <Row gap="xs" verticalAlign="center">
              <Col gap="xs" xs="auto">
                <Button href={routes.homePage} size="md" theme="button">
                  Back to homepage
                </Button>
              </Col>
              <Col gap="xs" xs="auto">
                <Button
                  icon={<ChatIcon />}
                  iconPosition="before"
                  iconSize={14}
                  size="md"
                  theme="button"
                  variant="secondary"
                  onClick={showLiveChat}
                >
                  Chat with support
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={5}>
            <Decoration position="bottom-right" size="sm" theme="blue">
              <Image alt="" height={325} src={cablesImage} theme="rounded" width={575} />
            </Decoration>
          </Col>
        </Row>
      </Section>
    </>
  );
};

import classNames from 'classnames/bind';
import React from 'react';

import styles from './Decoration.module.scss';

export interface IDecoration {
  size: 'sm' | 'md' | 'lg';
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  theme: 'yellow' | 'blue' | 'purple';
  className?: string;
  decorationClassName?: string;
}

type IProps = IDecoration & { children?: React.ReactNode };

const cx = classNames.bind(styles);

export const Decoration: React.FC<IProps> = ({
  size,
  position,
  theme,
  className,
  decorationClassName,
  children,
}) => (
  <div className={cx('wrapper', className)}>
    <div className={cx('decoration', decorationClassName, size, position, theme)} />
    {children}
  </div>
);
